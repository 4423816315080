const currentUrl = window.location.href;
function scrollHeader() {
  window.addEventListener("scroll", (e) => {
    const distanceScrolled = document.documentElement.scrollTop;
    const header = document.querySelector(".header ");

    if (distanceScrolled > 112) {
      header.style.background = "#2B343D";
      header.style.boxShadow = "0px 4px 4px 0px rgba(0, 0, 0, 0.25)";
    }

    if (distanceScrolled < 10) {
      header.style.removeProperty("background");
      header.style.removeProperty("boxShadow");
    }
  });
}

export { scrollHeader };
