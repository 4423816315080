let elementsArray = document.querySelectorAll(
  ".mekhanizirovannyye__process-grid"
);
function fadeIn() {
  for (let i = 0; i < elementsArray.length; i++) {
    const elem = elementsArray[i];
    const distInView =
      elem.getBoundingClientRect().top - window.innerHeight + 420;

    if (distInView < -10) {
      elem.classList.add("--active");
    } else {
      elem.classList.remove("--active");
    }
  }
}

export { fadeIn };
