let selectedTd;
let selectedTd2;
let selectedTd3;
let selectedTd4;
let selectedTd5;
let selectedTd6;
let selectedTd7;

function weMarkTheCheckBox(elem) {
  let td = elem.closest("input");
  if (!td) return;

  if (!elem.contains(td)) return;

  checked(elem);
}

function checked(ss) {
  if (selectedTd) {
    selectedTd.checked = false;
  }
  selectedTd = ss;
  selectedTd.checked = true;
}

function weMarkTheCheckBox2(elem) {
  let td = elem.closest("input");
  if (!td) return;

  if (!elem.contains(td)) return;

  checked2(elem);
}

function checked2(ss) {
  if (selectedTd2) {
    selectedTd2.checked = false;
  }
  selectedTd2 = ss;
  selectedTd2.checked = true;
}

function weMarkTheCheckBox3(elem) {
  let td = elem.closest("input");
  if (!td) return;

  if (!elem.contains(td)) return;

  checked3(elem);
}

function checked3(ss) {
  if (selectedTd3) {
    selectedTd3.checked = false;
  }
  selectedTd3 = ss;
  selectedTd3.checked = true;
}

function weMarkTheCheckBox4(elem) {
  let td = elem.closest("input");
  if (!td) return;

  if (!elem.contains(td)) return;

  checked4(elem);
}

function checked4(ss) {
  if (selectedTd4) {
    selectedTd4.checked = false;
  }
  selectedTd4 = ss;
  selectedTd4.checked = true;
}

function weMarkTheCheckBox5(elem) {
  let td = elem.closest("input");
  if (!td) return;

  if (!elem.contains(td)) return;

  checked5(elem);
}

function checked5(ss) {
  if (selectedTd5) {
    selectedTd5.checked = false;
  }
  selectedTd5 = ss;
  selectedTd5.checked = true;
}

function weMarkTheCheckBox6(elem) {
  let td = elem.closest("input");
  if (!td) return;

  if (!elem.contains(td)) return;

  checked6(elem);
}

function checked6(ss) {
  if (selectedTd6) {
    selectedTd6.checked = false;
  }
  selectedTd6 = ss;
  selectedTd6.checked = true;
}

function weMarkTheCheckBox7(elem) {
  let td = elem.closest("input");
  if (!td) return;

  if (!elem.contains(td)) return;

  checked7(elem);
}

function checked7(ss) {
  if (selectedTd7) {
    selectedTd7.checked = false;
  }
  selectedTd7 = ss;
  selectedTd7.checked = true;
}

export {
  weMarkTheCheckBox,
  weMarkTheCheckBox2,
  weMarkTheCheckBox3,
  weMarkTheCheckBox4,
  weMarkTheCheckBox5,
  weMarkTheCheckBox6,
  weMarkTheCheckBox7,
};
