import { sendMessage } from "./function/sendMessage";
import {
  weMarkTheCheckBox,
  weMarkTheCheckBox2,
  weMarkTheCheckBox3,
  weMarkTheCheckBox4,
  weMarkTheCheckBox5,
  weMarkTheCheckBox6,
  weMarkTheCheckBox7,
} from "./function/checked";
import Inputmask from "inputmask";
import { fadeIn } from "./function/fadeIn";
import LazyLoad from "vanilla-lazyload";
import Timeout from "await-timeout";
import { swiper, swiperSochial, questionnaire } from "./function/sliders";
import {
  openMobailMenu,
  closestPopap,
  closestMobailMenu,
} from "./function/menuMobail";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import { progresBar } from "./function/progresBar";
import { scrollHeader } from "./function/scrollHeader";

const timer = new Timeout();

const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
});

window.lazyLoadOptions = {};

window.addEventListener(
  "LazyLoad::Initialized",
  function (event) {
    window.lazyLoadInstance = event.detail.instance;
  },
  false
);

const formQuestionnaire = document.querySelector(".questionnaire__form");
const step1 = document.querySelector(".questionnaire__content-step_1");
const step2 = document.querySelector(".questionnaire__content-step_2");
const step3 = document.querySelector(".questionnaire__content-step_3");
const step4 = document.querySelector(".questionnaire__content-step_4");
const step5 = document.querySelector(
  ".questionnaire__content-center-wrapper-5"
);

const calculator = document.querySelector(".calculator__form");
const calculatorFormItems = document.querySelector(
  ".calculator__form-wrapper-item-van"
);
const calculatorFormItem = document.querySelector(
  ".calculator__form-wrapper-item-two"
);

const checBox = document.querySelectorAll('[type="checkbox"]');

checBox.forEach((item) => {
  item.checked = false;
});

const sliderRange = document.querySelector(".calculator__form-range");
const popapMesage = document.querySelector(".sendMessage");
const scoreTabl = document.querySelector(".calculator__form-score");
const header = document.querySelector(".header");

const currentUrl = window.location.href;
if (
  currentUrl === "https://rsbsochi.ru/o-nas" ||
  currentUrl === "https://rsbsochi.ru/otzyvy" ||
  currentUrl === "https://rsbsochi.ru/nashi-raboty" ||
  currentUrl === "https://rsbsochi.ru/oshibka" ||
  currentUrl === "https://rsbsochi.ru/zaglushka" ||
  currentUrl === "https://rsbsochi.ru/uslugi/elektromontazhnyye-raboty" ||
  currentUrl === "https://rsbsochi.ru/uslugi/remont-ploskoy-krovli"
) {
  header.style.backgroundColor = "rgb(43, 52, 61)";
} else {
  scrollHeader();
}

timer.set(3000).then(() => {
  Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });
});

if (scoreTabl !== null) {
  scoreTabl.innerHTML = sliderRange.value;

  sliderRange.oninput = function () {
    scoreTabl.innerHTML = this.value;
  };
}

window.addEventListener("scroll", fadeIn);

document.body.addEventListener("click", (e) => {
  const target = e.target;
  closestMobailMenu(target);
  openMobailMenu(target);
  closestPopap(target);

  if (target.getAttribute("id") === "modal") {
    popapMesage.classList.add("activMessage");
    const form = document.querySelector(".sendMessage__form");
    Inputmask({ mask: "+7 (999)-999-99-99" }).mask(form[1]);

    const clickLocation = e.target.dataset.modal;
    form.addEventListener("click", async (e) => {
      if (e.target.tagName === "BUTTON") {
        e.preventDefault();
        const servicesName = clickLocation;
        const name = form[0].value.trim();
        const phone = form[1].value.trim();
        let result = false;

        const mas = Inputmask.unmask(form[1].value, {
          mask: "+7 (999)-999-99-99",
        });

        if (name.length > 1 && mas.split("").length === 10) {
          result = name.length > 1 && mas.split("").length === 10;
        }

        if (result) {
          alertError(false, ".sendMessage__form-error-sendMessage");
        } else {
          alertError(true, ".sendMessage__form-error-sendMessage");
        }

        if (result) {
          const messageData = {
            clickLocation: servicesName,
            nameUser: name,
            phoneUser: phone,
          };

          const response = await sendMessage(
            "https://rsbsochi.ru/message",
            messageData
          );

          if (response) {
            const notification = document.querySelector(".notification");
            notification?.classList.add("notificationActiv");
            popapMesage.classList.remove("activMessage");
            form[0].value = "";
            form[1].value = "";
            setTimeout(() => {
              notification?.classList.remove("notificationActiv");
            }, 3000);
          } else {
            alert("Ошибка отправки формы");
          }
        }
      }
    });
  }
  progresBar();
});

let message = {
  questionOne: "",
  questionTwo: "",
};

if (formQuestionnaire) {
  formQuestionnaire.addEventListener("click", async function (e) {
    const questionnaireTabl = document.querySelectorAll(
      ".questionnaire__content-checking"
    );

    Inputmask({ mask: "+7 (999)-999-99-99" }).mask(this[26]);

    if (
      e.target.id === "prev2" ||
      e.target.id === "prev3" ||
      e.target.id === "prev4"
    ) {
      e.preventDefault();
      questionnaire.slidePrev();
    }

    if (e.target.id === "next1") {
      e.preventDefault();
      if (this[0].checked || this[1].checked || this[2].checked) {
        bypassingArray(questionnaireTabl, "errorQ", false);
        (message.questionOne = this[0].checked
          ? this[0].name
          : false || this[1].checked
          ? this[1].name
          : false || this[2].checked
          ? this[2].name
          : false),
          questionnaire.slideNext();
      } else {
        bypassingArray(questionnaireTabl, "errorQ", true);
      }
    }

    if (e.target.id === "next2") {
      e.preventDefault();
      if (
        this[4].checked ||
        this[5].checked ||
        this[6].checked ||
        this[7].checked
      ) {
        bypassingArray(questionnaireTabl, "errorQ", false);
        (message.questionTwo = this[4].checked
          ? this[4].name
          : false || this[5].checked
          ? this[5].name
          : false || this[6].checked
          ? this[6].name
          : false || this[7].checked
          ? this[7].name
          : false),
          questionnaire.slideNext();
      } else {
        bypassingArray(questionnaireTabl, "errorQ", true);
      }
    }

    if (e.target.id === "next3") {
      e.preventDefault();
      const que4 = document.querySelector(".questionnaire__content-wrapper4");
      if (this[10].checked || this[11].checked) {
        bypassingArray(questionnaireTabl, "errorQ", false);
        message.questionThree = this[10].checked
          ? this[10].name
          : false || this[11].checked
          ? this[11].name
          : false;
        que4.style.display = "flex";
        questionnaire.slideNext();
      } else {
        bypassingArray(questionnaireTabl, "errorQ", true);
      }
    }

    if (e.target.id === "next4") {
      const questionnaire5 = document.querySelector(
        ".questionnaire__content-step_5-wrapper"
      );
      e.preventDefault();
      if (
        this[14].checked ||
        this[15].checked ||
        this[16].checked ||
        this[17].checked
      ) {
        bypassingArray(questionnaireTabl, "errorQ", false);
        message.questionFour = this[14].checked
          ? this[14].name
          : false || this[15].checked
          ? this[15].name
          : false || this[16].checked
          ? this[16].name
          : false || this[17].checked
          ? this[17].name
          : false;

        message.questionF = this[18].value ? this[18].value : "Пусто";
        message.questionR = this[19].value ? this[19].value : "Пусто";
        questionnaire5.style.display = "flex";
        questionnaire.slideNext();
      } else {
        bypassingArray(questionnaireTabl, "errorQ", true);
      }
    }

    if (e.target.id === "end") {
      e.preventDefault();
      if (this[22].checked || this[23].checked || this[24].checked) {
        bypassingArray(questionnaireTabl, "errorQ", false);
        const mas = Inputmask.unmask(this[26].value, {
          mask: "+7 (999)-999-99-99",
        });
        alertError(false, ".sendMessage__form-error-question");

        if (this[25].value.length > 0 && mas.split("").length === 10) {
          message.final = this[22].checked
            ? this[22].name
            : false || this[23].checked
            ? this[23].name
            : false || this[24].checked
            ? this[24].name
            : false;
          message.name = this[25].value;
          message.phone = this[26].value;

          const response = await sendMessage(
            "https://rsbsochi.ru/messageQu",
            message
          );

          if (response) {
            alertError(false, ".sendMessage__form-error-sendMessage");
          } else {
            alertError(true, ".sendMessage__form-error-sendMessage");
          }

          if (response) {
            const notification = document.querySelector(".notification");
            notification?.classList.add("notificationActiv");
            popapMesage.classList.remove("activMessage");
            this[25].value = "";
            this[26].value = "";

            setTimeout(() => {
              notification?.classList.remove("notificationActiv");
            }, 3000);
          } else {
            alert("Ошибка отправки формы");
          }
        }
      } else {
        bypassingArray(questionnaireTabl, "errorQ", true);
      }
    }
  });
}

if (calculator) {
  calculator.addEventListener("click", async function (e) {
    Inputmask({ mask: "+7 (999)-999-99-99" }).mask(this[10]);

    if (e.target.tagName === "BUTTON") {
      e.preventDefault();
      const score = document.querySelector(".calculator__form-score");
      const phone = this[10].value.trim();
      let formInfo = {
        score: score.textContent,
        type: this[1].checked
          ? this[1].name
          : false || this[2].checked
          ? this[2].name
          : false || this[3].checked
          ? this[3].name
          : false || this[4].checked
          ? this[4].name
          : false || this[5].checked
          ? this[5].name
          : false,
        type2: this[6].checked
          ? this[6].name
          : false || this[7].checked
          ? this[7].name
          : false || this[8].checked
          ? this[8].name
          : false || this[9].checked
          ? this[9].name
          : false,
        phone: phone,
      };

      const mas = Inputmask.unmask(this[10].value, {
        mask: "+7 (999)-999-99-99",
      });

      if (formInfo.type && formInfo.type2 && mas.split("").length === 10) {
        const response = await sendMessage(
          "https://rsbsochi.ru/messageCal",
          formInfo
        );

        if (response) {
          alertError(false, ".sendMessage__form-error-sendMessage");
        } else {
          alertError(true, ".sendMessage__form-error-sendMessage");
        }

        if (response) {
          const notification = document.querySelector(".notification");
          notification?.classList.add("notificationActiv");
          this[10].value = "";
          setTimeout(() => {
            notification?.classList.remove("notificationActiv");
          }, 3000);
        } else {
          alert("Ошибка отправки формы");
        }
      }
    }
  });
}
function alertError(boolean, selector) {
  const error = document.querySelector(selector);

  if (boolean) {
    error.style.display = "block";
  } else {
    error.style.removeProperty("display");
  }
}

if (step1) {
  step1.addEventListener("click", function (e) {
    const target = e.target;
    if (target !== null && target !== undefined) {
      weMarkTheCheckBox3(target);
    }
  });
}

if (step2) {
  step2.addEventListener("click", function (e) {
    const target = e.target;
    if (target !== null && target !== undefined) {
      weMarkTheCheckBox4(target);
    }
  });
}

if (step3) {
  step3.addEventListener("click", function (e) {
    const target = e.target;
    if (target !== null && target !== undefined) {
      weMarkTheCheckBox5(target);
    }
  });
}

if (step4) {
  step4.addEventListener("click", function (e) {
    const target = e.target;
    if (target !== null && target !== undefined) {
      weMarkTheCheckBox6(target);
    }
  });
}

if (step5) {
  step5.addEventListener("click", function (e) {
    const target = e.target;
    if (target !== null && target !== undefined) {
      weMarkTheCheckBox7(target);
    }
  });
}

if (calculatorFormItems) {
  calculatorFormItems.addEventListener("click", function (e) {
    const target = e.target;
    if (target !== null && target !== undefined) {
      weMarkTheCheckBox(target);
    }
  });
}

if (calculatorFormItem) {
  calculatorFormItem.addEventListener("click", function (e) {
    const target = e.target;
    if (target !== null && target !== undefined) {
      weMarkTheCheckBox2(target);
    }
  });
}

function bypassingArray(arr, className, isTrue) {
  if (isTrue) {
    arr.forEach((item) => {
      item.classList.add(className);
    });
  } else {
    arr.forEach((item) => {
      item.classList.remove(className);
    });
  }
}

const mainDiv = document.getElementById("main-button");
mainDiv.addEventListener("click", function () {
  this.children.item(0).classList.toggle("fa-times");
  this.classList.toggle("open");
});


