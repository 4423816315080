const dot2 = document.querySelector(".questionnaire__bar-dot-item-post-2");
const dot3 = document.querySelector(".questionnaire__bar-dot-item-post-3");
const dot4 = document.querySelector(".questionnaire__bar-dot-item-post-4");
const dot5 = document.querySelector(".questionnaire__bar-dot-item-post-5");

const progressBar = document.querySelector(
  ".swiper-pagination-progressbar-fill"
);

function progresBar() {
  if (progressBar !== null) {
    if (
      progressBar.style.transform ===
        "translate3d(0px, 0px, 0px) scaleX(0.4) scaleY(1)" ||
      progressBar.style.transform ===
        "translate3d(0px, 0px, 0px) scaleX(0.6) scaleY(1)" ||
      progressBar.style.transform ===
        "translate3d(0px, 0px, 0px) scaleX(0.8) scaleY(1)" ||
      progressBar.style.transform ===
        "translate3d(0px, 0px, 0px) scaleX(1) scaleY(1)"
    ) {
      dot2.classList.add("activ");
    } else {
      dot2.classList.remove("activ");
    }

    if (
      progressBar.style.transform ===
        "translate3d(0px, 0px, 0px) scaleX(0.6) scaleY(1)" ||
      progressBar.style.transform ===
        "translate3d(0px, 0px, 0px) scaleX(0.8) scaleY(1)" ||
      progressBar.style.transform ===
        "translate3d(0px, 0px, 0px) scaleX(1) scaleY(1)"
    ) {
      dot3.classList.add("activ");
    } else {
      dot3.classList.remove("activ");
    }

    if (
      progressBar.style.transform ===
        "translate3d(0px, 0px, 0px) scaleX(0.8) scaleY(1)" ||
      progressBar.style.transform ===
        "translate3d(0px, 0px, 0px) scaleX(1) scaleY(1)" ||
      progressBar.style.transform ===
        "translate3d(0px, 0px, 0px) scaleX(1) scaleY(1)"
    ) {
      dot4.classList.add("activ");
    } else {
      dot4.classList.remove("activ");
    }

    if (
      progressBar.style.transform ===
      "translate3d(0px, 0px, 0px) scaleX(1) scaleY(1)"
    ) {
      dot5.classList.add("activ");
    } else {
      dot5.classList.remove("activ");
    }
  }
}

export { progresBar };
