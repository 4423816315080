import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
Swiper.use([EffectFade]);

const swiper = new Swiper(".slider__repair-slid-swiper", {
  lazy: true,

  modules: [Navigation],
  navigation: {
    nextEl: ".slider__button-next",
    prevEl: ".slider__button-prev",
  },
});

const swiperSochial = new Swiper(".social__network-slids", {
  lazy: true,

  modules: [Navigation],
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const questionnaire = new Swiper(".questionnaire__form", {
  fadeEffect: { crossFade: true },
  effect: "fade",
  simulateTouch: false,
  allowTouchMove: false,

  modules: [Pagination],

  pagination: {
    el: ".questionnaire__bar-dot-progress",
    type: "progressbar",
    hide: true,
  },
});

const swiperMebel = new Swiper(".mebel__slider", {
  lazy: true,

  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 3,
    },
    920: {
      slidesPerView: 4,
    },
  },
});

const potolkiSlider = new Swiper(".potolki__slider", {
  lazy: true,
  spaceBetween: 11,

  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 3,
    },
    920: {
      slidesPerView: 4,
    },
  },
});

export { swiper, swiperSochial, questionnaire, swiperMebel, potolkiSlider };
