async function sendMessage(url = "", data = {}) {
  // let controller = new AbortController();
  // setTimeout(() => controller.abort(), 2000);

  try {
    const response = await fetch(url, {
      // signal: controller.signal,
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });

    if (response.ok) {
      let json = await response.json();

      return json;
    }

    if (err.name == "AbortError") {
      console.log("Прервано!");
    } else {
      throw err;
    }
  } catch (e) {
    console.log(e);
  }
}

export { sendMessage };
