const mobailMenu = document.querySelector(".mobail__nav");
const popapMesage = document.querySelector(".sendMessage");
const reviewsBtn = document.querySelectorAll(".reviews__item-card-text-answer");
const questionsElement = document.querySelectorAll(".questions_item");

function openMobailMenu(btn) {
  if (btn.closest(".nav-btn")) {
    mobailMenu.classList.add("mobailMenuActiv");
  }
}

function closestMobailMenu(target) {
  if (mobailMenu.classList[1]) {
    if (!target.closest(".mobail__nav-list")) {
      mobailMenu.classList.remove("mobailMenuActiv");
    }
  }
}

function closestPopap(target) {
  if (popapMesage !== null) {
    if (popapMesage.classList[1]) {
      if (!target.closest(".sendMessage__form")) {
        popapMesage.classList.remove("activMessage");
      }
    }
  }
}

mobailMenu.addEventListener("click", (e) => {
  if (e.target.closest(".mobail__nav-list-link-e")) {
    e.target
      .closest(".mobail__nav-list-link-e")
      .closest(".mobail__nav-list")
      .querySelector(".mobail__submenu")
      .classList.toggle("activSubMenu");
  }
});

reviewsBtn.forEach((btns) => {
  btns.addEventListener("click", function () {
    if (
      !this.closest(".reviews__item-card").querySelector(
        ".business-review-comment__comment"
      ).classList[1]
    ) {
      this.closest(".reviews__item-card")
        .querySelector(".business-review-comment__comment")
        .classList.add("activReview");
      this.textContent = "- Скрыть ответ организации";
    } else {
      this.closest(".reviews__item-card")
        .querySelector(".business-review-comment__comment")
        .classList.remove("activReview");
      this.textContent = "- Посмотреть ответ организации";
    }
  });
});

questionsElement.forEach((item) => {
  item.addEventListener("click", function () {
    if (!this.querySelector(".questions_item-commit").classList[1]) {
      this.querySelector(".questions_item-commit").classList.add(
        "activQuestions"
      );

      this.firstChild.style.background = "#2b343d";
      this.firstChild.style.color = "#fff";
      this.firstChild.lastChild.style.transform = "rotate(180deg)";
    } else {
      this.querySelector(".questions_item-commit").classList.remove(
        "activQuestions"
      );
      this.firstChild.style.removeProperty("background");
      this.firstChild.style.removeProperty("color");
      this.firstChild.lastChild.style.removeProperty("transform");
    }
  });
});

export { openMobailMenu, closestPopap, closestMobailMenu };
